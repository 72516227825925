import styles from "./QuestionCard.module.sass";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../../shared/images/arrow-right.svg";
import { IndexCounter } from "./IndexCounter";
import {
  selectAnswer,
  previousQuestion,
  submitSelectedAnswer,
} from "./quizSlice";
import { CSSTransition } from "react-transition-group";

import React from "react";
import { Constants } from "../../shared/constants";
import { useTranslation } from "react-i18next";

export function QuestionCard({
  id,
  index,
  title,
  subtitle,
  answers,
  images,
  topImage,
  selectAnswerFunc = selectAnswer,
  previousQuestionFunc = previousQuestion,
  submitSelectedAnswerFunc = submitSelectedAnswer,
  namespace = "my_quiz",
}) {
  const dispatch = useDispatch();

  // hacky way to use the correct selector for personalitytest
  const isPersonalityTest = selectAnswerFunc !== selectAnswer;
  const selectedAnswer = useSelector((state) => {
    if (isPersonalityTest) {
      return state.personalityQuiz.selectedAnswer;
    } else {
      return state.quiz.selectedAnswer;
    }
  });
  const nodeRef = React.useRef(null);
  const { t } = useTranslation();

  return (
    <CSSTransition
      key={index}
      classNames="fade"
      in={true}
      appear
      unmountOnExit
      nodeRef={nodeRef}
      timeout={500}
    >
      <div ref={nodeRef}>
        <div className={styles.questionCard}>
          <div className={styles.indexCounter}>
            <IndexCounter isPersonalityTest={isPersonalityTest} />
          </div>
          <h3 className={styles.title}>
            {t(`${namespace}:questions.${title}`, title)}
          </h3>
          {subtitle && (
            <h4>{t(`${namespace}:questions.${subtitle}`, subtitle)}</h4>
          )}
          {topImage && (
            <div className={styles.topImage}>
              <img
                src={`${Constants.apiUrl}/static/${topImage}`}
                alt="Visual question"
              />
            </div>
          )}
          <div className={images && styles.imageAnswers}>
            {answers.map((answer, index) => (
              <label htmlFor={answer.title} key={answer.title}>
                <div
                  className={`${styles.answer} ${images && styles.answerImage}`}
                >
                  <input
                    type="radio"
                    id={answer.title}
                    value={answer.title}
                    name="quiz-radio"
                    checked={selectedAnswer === answer.title}
                    onChange={() => dispatch(selectAnswerFunc(answer.title))}
                  />
                  <div className={styles.checkmark}></div>
                  <span className={styles.label}>
                    {t(`${namespace}:questions.${answer.title}`, answer.title)}
                  </span>
                  {images && (
                    <img
                      src={`${Constants.apiUrl}/static/${images[index]}`}
                      alt={`Visual answer ${answer.title}`}
                    />
                  )}
                </div>
              </label>
            ))}
          </div>
          <div className={styles.buttonRow}>
            <button
              className={styles.buttonLeft}
              title="Vorige vraag"
              disabled={index === 0}
              onClick={() => dispatch(previousQuestionFunc())}
            >
              <img src={arrow} alt="Vorige vraag" />
            </button>
            <button
              className={styles.buttonRight}
              title="Volgende vraag"
              disabled={selectedAnswer === null}
              onClick={() => dispatch(submitSelectedAnswerFunc())}
            >
              <img src={arrow} alt="Volgende vraag" />
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
