export const Constants = Object.freeze({
  // apiUrl: "http://localhost:8000",
  //apiUrl: "https://quizzes-api-pv4ss.ondigitalocean.app",
  apiUrl: "https://test-api.wincacademy.nl",
  redirectUrl: "https://wincacademy.nl",
  redirectDuration: "3000", // milliseconds
  mentaalVermogenTestDuration: 30, // minutes
  showLanguageSelect: false,
  hubspot: {
    portalId: "5149744",
    personalityTestFormId: "735e274f-c07d-4850-ae7a-17c61a854e81",
    personalityTestFormIdEn: "d6eeb6ea-59b3-4e39-aefc-8b37ea8c5dad",
    personalityTestFormIdDe: "0f015919-baf8-4802-beec-24a44b6743ca",
    itCareerTestFormId: "5c4aa8db-4179-4fa4-8cdd-4b8117679249",
    itCareerTestFormIdEn: "dee0468c-5246-4cb4-8a9e-48b84b6cc987",
    mvtFormId: "3837676d-eba6-46e6-8e7f-d0be9ead01dc",
    mvtFormIdEn: "1eeedb59-3143-4371-a8bf-652219e931bb",
    htmlCssTestFormId: "41fe149c-d0e7-4d07-922f-06839c328b53",
  },
  redirects: {
    personalityTest: "https://www.wincacademy.nl/bedankt?name=Persoonlijkheid",
    personalityTestEn: "https://www.wincacademy.com/thank-you/personality-test",
    personalityTestDe: "https://www.wincacademy.com/thank-you/personality-test",
    itCareerTest:
      "https://www.wincacademy.nl/bedankt-it-carriere-test?type=Test&name=IT%20carri%C3%A8re",
    itCareerTestEn: "https://www.wincacademy.com/thank-you/%20it-career-test",
    mvt: "https://www.wincacademy.nl/bedankt?type=Test&name=Mentaal%20Vermogen%20Test",
    htmlCssTest:
      "https://www.wincacademy.nl/bedankt?type=Test&name=HTML%20CSS%20Test",
  },
});
