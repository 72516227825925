import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../shared/constants";
import ApiContainer from "../api/ApiContainer";
import { fetchQuestions, submitAnswers } from "../api/apiSlice";
import { QuizContainer } from "../quiz/QuizContainer";
import { startQuiz } from "../quiz/quizSlice";
import { SubmitScreen } from "../quiz/SubmitScreen";
import { SubmittedScreen } from "../quiz/SubmittedScreen";
import { startTimer } from "../quiz/timerSlice";
import { WelcomeScreen } from "../quiz/WelcomeScreen";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function MentaalVermogenTest() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.api.questions);
  const answers = useSelector((state) => state.quiz.answers);
  const query = useQuery();
  const quizType = query.get("quizType") || "hbo1";
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchQuestions(`/questions/mvt/${quizType}`));
  }, [dispatch, quizType]);

  const onSubmit = (submission) => {
    submission.quizType = `mentaal-vermogen-test-${quizType}`;
    submission.answers = answers;
    submission.app_language = i18n.language;
    dispatch(submitAnswers(submission));
  };

  return (
    <div>
      <Helmet>
        <title>{t("mvt:header")}</title>
      </Helmet>
      <ApiContainer
        success={
          <QuizContainer
            namespace={`mvt_${quizType}`}
            welcomeScreen={
              <WelcomeScreen
                title={t("mvt:header")}
                subtitle={
                  <div>
                    <p>{t("mvt:title")}</p>
                    <p>{t("mvt:subtitle0")}</p>
                    <ul>
                      <li>{t("mvt:subtitle1")}</li>
                      <li>{t("mvt:subtitle2")}</li>
                    </ul>
                    <p>{t("mvt:subtitle3")}</p>
                  </div>
                }
                startButton={
                  <button
                    onClick={() => {
                      dispatch(
                        startTimer(Constants.mentaalVermogenTestDuration)
                      );
                      dispatch(startQuiz(questions));
                    }}
                  >
                    {t("app.startTest")}
                  </button>
                }
              />
            }
            submitScreen={
              <SubmitScreen
                hubspotFormId={
                  i18n.language.includes("en")
                    ? Constants.hubspot.mvtFormIdEn
                    : Constants.hubspot.mvtFormId
                }
                onSubmit={(submission) => {
                  onSubmit(submission);
                }}
              />
            }
          />
        }
        submitted={<SubmittedScreen redirectUrl={Constants.redirects.mvt} />}
      />
    </div>
  );
}
