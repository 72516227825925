import { QuestionCard } from "./QuestionCard";
import { useSelector } from "react-redux";
import { QuizProgress } from "./quizSlice";

export function QuizContainer({ welcomeScreen, submitScreen, namespace }) {
  const progress = useSelector((state) => state.quiz.progress);
  const index = useSelector((state) => state.quiz.index);
  const questions = useSelector((state) => state.quiz.questions);

  const renderQuiz = () => {
    switch (progress) {
      case QuizProgress.initial:
      default:
        return welcomeScreen;

      case QuizProgress.active:
        const question = questions[index];
        let params = { ...question };
        params.index = index;
        params.namespace = namespace;
        return <QuestionCard {...params} />;

      case QuizProgress.done:
        return submitScreen;
    }
  };

  return <div>{renderQuiz()}</div>;
}
