import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maxMinutes: 0,
};

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    startTimer: (state, action) => {
      state.maxMinutes = action.payload;
      state.tick = 0;
    },
    addTick: (state, action) => {
      state.tick = state.tick + 1;
    },
  },
});

export const { startTimer, addTick } = timerSlice.actions;

export default timerSlice.reducer;
