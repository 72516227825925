import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ApiContainer({ success, submitted, loading = <div>...</div> }) {
  const { t } = useTranslation();
  const status = useSelector((state) => state.api.status);

  return (
    <div>
      {status === "loading" && loading}
      {status === "failed" && (
        <div>
          <p>{t("app.submitted")}</p>
          <a href={t("app.homeUrl")}>
            <button>Home</button>
          </a>
        </div>
      )}
      {status === "success" && success}
      {status === "submitted" && submitted}
    </div>
  );
}

export default ApiContainer;
