import { useTranslation } from "react-i18next";

import styles from "./LanguageSelectButton.module.sass";

const lngs = {
  nl: { nativeName: "Nederlands" },
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

export function LanguageSelectButton() {
  const { i18n } = useTranslation();

  const onSelect = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div>
      <div>
        <select
          className={styles.selecter}
          name="langs"
          id="langs"
          onChange={onSelect}
          defaultValue={i18n.language.split("-")[0]}
        >
          {Object.keys(lngs).map((lng) => (
            <option
              key={lng}
              value={lng}
              name={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
            >
              {lngs[lng].nativeName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
