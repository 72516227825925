import { CSSTransition } from "react-transition-group";
import styles from "./SubmitScreen.module.sass";
import React, { useState } from "react";
import HubspotFormContainer from "../hubspot/HubspotFormContainer";

export function SubmitScreen({ hubspotFormId, onSubmit }) {
  const nodeRef = React.useRef(null);
  const [ready, setReady] = useState(false);

  const onHubSpotFormSubmit = () => {
    const form = document.querySelector("iframe").contentDocument.forms[0];
    const data = new FormData(form);
    let submission = {};
    data.forEach((value, key) => (submission[key] = value));
    console.debug("waiting for HubSpot...");
    // delay submission so Hubspot has the time to submit the form entry
    setTimeout(() => {
      onSubmit(submission);
    }, 1500);
  };

  return (
    <CSSTransition classNames="fade" in={ready} nodeRef={nodeRef} timeout={500}>
      <div ref={nodeRef}>
        <div className={styles.submitScreen}>
          {ready && process.env.NODE_ENV === "development" && (
            <button onClick={() => onHubSpotFormSubmit()}>Dev submit</button>
          )}
          <HubspotFormContainer
            hubspotFormId={hubspotFormId}
            onReady={() => setReady(true)}
            onSubmit={() => onHubSpotFormSubmit()}
          />
        </div>
      </div>
    </CSSTransition>
  );
}
