import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.sass";
import { HtmlCssTest } from "./features/html_css_test/HtmlCssTest";
import { ItCareerTest } from "./features/it_career_test/ItCareerTest";
import { MentaalVermogenTest } from "./features/mvt/MentaalVermogenTest";
import { PersonalityTest } from "./features/personality_test/PersonalityTest";
import { ConfirmExit } from "./features/quiz/ConfirmExit";
import { QuizTimer } from "./features/quiz/QuizTimer";
import { SelectionScreen } from "./features/quiz/SelectionScreen";
import { AppHeader } from "./shared/AppHeader";

function App() {
  return (
    <div className="App">
      <Router>
        <AppHeader />
        <QuizTimer />
        <ConfirmExit />

        <div className="content">
          <Switch>
            <Route exact path="/" component={SelectionScreen} />
            <Route
              path={["/persoonlijkheidstest", "/personality", "/de/persönlichkeitstest"]}
              component={PersonalityTest}
            />
            <Route path="/testen/persoonlijkheidstest">
              <Redirect to="/persoonlijkheidstest" />
            </Route>
            <Route
              path={["/mentaal-vermogen-test", "/ability-test", "/ability"]}
              component={MentaalVermogenTest}
            />
            <Route
              path={[
                "/it-carriere-test",
                "/it-carrière-test",
                "/it-career-test",
                "/it-career",
              ]}
              component={ItCareerTest}
            />
            <Route path="/html-css-test" component={HtmlCssTest} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
