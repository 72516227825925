import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function SelectionScreen() {
  const { t } = useTranslation();
  return (
    <nav>
      <h2>{t("app.pickATest")}:</h2>
      <ul>
        <li>
          <Link to="/persoonlijkheidstest">{t("personality_test:header")}</Link>
        </li>
        <li>
          <Link to="/mentaal-vermogen-test">{t("mvt:header")}</Link>
        </li>
        <li>
          <Link to="/it-carriere-test">IT Carrière Test</Link>
        </li>

        <li>
          <Link to="/html-css-test">HTML & CSS Test</Link>
        </li>
      </ul>
    </nav>
  );
}
