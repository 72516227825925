import { CSSTransition } from "react-transition-group";
import logo from "../../shared/logos/main-logo.png";
import styles from "./WelcomeScreen.module.sass";
import React from "react";

export function WelcomeScreen({ title, subtitle, startButton }) {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      classNames="fade"
      in
      unmountOnExit
      appear
      nodeRef={nodeRef}
      timeout={500}
    >
      <div ref={nodeRef}>
        <div className={styles.welcome}>
          <img src={logo} alt="Logo" />

          <h2>{title}</h2>
          <div>{subtitle}</div>
          {startButton}
        </div>
      </div>
    </CSSTransition>
  );
}
