import { createSlice } from "@reduxjs/toolkit";
export const QuizProgress = Object.freeze({
  initial: "initial",
  active: "active",
  done: "done",
  submitted: "submitted",
});

const initialState = {
  questions: [],
  progress: QuizProgress.initial,
  index: 0,
  answers: {},
  selectedAnswer: null,
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    startQuiz: (state, action) => {
      state.questions = action.payload;
      state.index = 0;
      state.answers = {};
      state.selectedAnswer = null;
      state.progress = QuizProgress.active;
    },
    selectAnswer: (state, action) => {
      state.selectedAnswer = action.payload;
    },
    submitSelectedAnswer: (state) => {
      if (state.selectedAnswer !== null) {
        const question = state.questions[state.index];
        state.answers[question.id] = state.selectedAnswer;

        state.index += 1;
        state.selectedAnswer = null;

        if (state.index < state.questions.length) {
          const nextQuestion = state.questions[state.index];
          if (nextQuestion.id in state.answers) {
            state.selectedAnswer = state.answers[nextQuestion.id];
          }
        } else if (state.index >= state.questions.length) {
          state.progress = QuizProgress.done;
        }
      }
    },
    previousQuestion: (state) => {
      if (state.index === 0) {
        state.progress = QuizProgress.initial;
      } else {
        state.index -= 1;
        const question = state.questions[state.index];
        state.selectedAnswer = state.answers[question.id];
        state.progress = QuizProgress.active;
      }
    },
    finishQuiz: (state) => {
      state.progress = QuizProgress.done;
    },
    submitQuiz: (state) => {
      state.progress = QuizProgress.submitted;
    },
  },
});

export const {
  startQuiz,
  selectAnswer,
  submitSelectedAnswer,
  previousQuestion,
  finishQuiz,
  submitQuiz,
} = quizSlice.actions;

export default quizSlice.reducer;
