import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { finishQuiz, QuizProgress } from "../quiz/quizSlice";
import { addTick } from "./timerSlice";
import styles from "./QuizTimer.module.sass";

export function QuizTimer() {
  const { t } = useTranslation();
  const maxMinutes = useSelector((state) => state.timer.maxMinutes);
  const progress = useSelector((state) => state.quiz.progress);
  const tick = useSelector((state) => state.timer.tick);
  const dispatch = useDispatch();

  const secondsRemaining = maxMinutes * 60 - tick;
  const minutesRemaining = Math.floor(secondsRemaining / 60);

  useEffect(() => {
    if (progress === QuizProgress.active && maxMinutes > 0) {
      const interval = setInterval(() => {
        dispatch(addTick());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, tick, maxMinutes, progress]);

  useEffect(() => {
    if (secondsRemaining <= 0 && process.env.NODE_ENV === "production") {
      dispatch(finishQuiz());
    }
  });

  if (progress !== QuizProgress.active || maxMinutes === 0) {
    return null;
  }

  const progressStyles = {
    width: `${(secondsRemaining / (maxMinutes * 60)) * 100}%`,
  };

  return (
    <div className={styles.quizTimer}>
      <div className={styles.progress} style={progressStyles}></div>
      <span>
        {t("app.time")}: {minutesRemaining}:
        {(secondsRemaining % 60).toString().padStart(2, "0")} / {maxMinutes}
        :00
      </span>
    </div>
  );
}
