import { createSlice } from "@reduxjs/toolkit";
export const PersonalityQuizProgress = Object.freeze({
  initial: "initial",
  firstHalf: "firstHalf",
  firstHalfDone: "firstHalfDone",
  secondHalf: "secondHalf",
  done: "done",
  submitted: "submitted",
});

const initialState = {
  questions: [],
  progress: PersonalityQuizProgress.initial,
  index: 0,
  answers: {},
  selectedAnswer: null,
};

export const personalityQuizSlice = createSlice({
  name: "personalityQuiz",
  initialState,
  reducers: {
    startQuiz: (state, action) => {
      state.questions = action.payload;
      state.index = 0;
      state.answers = {};
      state.selectedAnswer = null;
      state.progress = PersonalityQuizProgress.firstHalf;
    },
    startFollowupQuiz: (state, action) => {
      state.extra_answers = state.answers;
      state.questions = action.payload;
      state.index = 0;
      state.answers = {};
      state.selectedAnswer = null;
      state.progress = PersonalityQuizProgress.secondHalf;
    },
    selectAnswer: (state, action) => {
      state.selectedAnswer = action.payload;
    },
    submitSelectedAnswer: (state) => {
      if (state.selectedAnswer !== null) {
        const question = state.questions[state.index];
        state.answers[question.id] = state.selectedAnswer;

        state.index += 1;
        state.selectedAnswer = null;

        if (state.index < state.questions.length) {
          const nextQuestion = state.questions[state.index];
          if (nextQuestion.id in state.answers) {
            state.selectedAnswer = state.answers[nextQuestion.id];
          }
        } else if (state.index >= state.questions.length) {
          if (state.progress === PersonalityQuizProgress.firstHalf) {
            state.progress = PersonalityQuizProgress.firstHalfDone;
          } else {
            state.progress = PersonalityQuizProgress.done;
          }
        }
      }
    },
    previousQuestion: (state) => {
      if (state.index === 0) {
        state.progress = PersonalityQuizProgress.initial;
      } else {
        state.index -= 1;
        const question = state.questions[state.index];
        state.selectedAnswer = state.answers[question.id];
      }
    },
    finishQuiz: (state) => {
      state.progress = PersonalityQuizProgress.done;
    },
    submitQuiz: (state) => {
      state.progress = PersonalityQuizProgress.submitted;
    },
  },
});

export const {
  startQuiz,
  startFollowupQuiz,
  selectAnswer,
  submitSelectedAnswer,
  previousQuestion,
  finishQuiz,
  submitQuiz,
} = personalityQuizSlice.actions;

export default personalityQuizSlice.reducer;
