import { CSSTransition } from "react-transition-group";
import logo from "../../shared/logos/main-logo.png";
import styles from "./SubmittedScreen.module.sass";
import React, { useEffect } from "react";
import { Constants } from "../../shared/constants";
import { useTranslation } from "react-i18next";

export function SubmittedScreen({ redirectUrl }) {
  const nodeRef = React.useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      console.debug(`Navigating to ${redirectUrl}`);
      if (process.env.NODE_ENV === "production" && redirectUrl !== null) {
        window.location.replace(redirectUrl);
      }
    }, Constants.redirectDuration);
  }, [redirectUrl]);

  return (
    <CSSTransition
      classNames="fade"
      in
      unmountOnExit
      appear
      nodeRef={nodeRef}
      timeout={500}
    >
      <div ref={nodeRef}>
        <div className={styles.submittedScreen}>
          <img src={logo} alt="Logo" />

          <p>{t("app.submitted")}</p>
          <p></p>
          <p>{t("app.forwarding")}</p>
        </div>
      </div>
    </CSSTransition>
  );
}
