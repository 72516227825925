import styles from "./AppHeader.module.sass";
import logo from "./logos/round-logo.svg";
import { LanguageSelectButton } from "./LanguageSelectButton";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Constants } from "./constants";

export function AppHeader() {
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <a
        className={styles.logoContainer}
        href={t("app.homeUrl")}
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.logo} src={logo} alt="Logo" />
        <span className={styles.title}>WINC Academy</span>
      </a>
      <div className={styles.contacts}>
        <div className={i18n.language.includes("en") ? "hide" : ""}>
          <img
            src="https://global-uploads.webflow.com/5ee34869dd28cd4237e2a5f2/5f23ece06ddecd365ea4557c_clock-blue.svg"
            alt="Openingstijd"
          />
          <span className={styles.selectable}>{t("app.openingHours")}</span>
        </div>
        <div>
          <img
            src="https://global-uploads.webflow.com/5ee34869dd28cd4237e2a5f2/5f23ec7c27b3a06733c1afdf_paper-plane.svg"
            alt="Email"
          />
          <span className={styles.selectableAll}>
            <a href={`mailto:${t("app.email")}`}>{t("app.email")}</a>
          </span>
        </div>
        <div>
          <img
            src="https://global-uploads.webflow.com/5ee34869dd28cd4237e2a5f2/5fd0f32d282bb9e201a60787_phone.svg"
            alt="Telefoon"
          />
          <span className={styles.selectableAll}>
            <a href={`tel:${t("app.phone")}`}>{t("app.phone")}</a>
          </span>
        </div>
        {Constants.showLanguageSelect && <LanguageSelectButton />}
      </div>
    </div>
  );
}
