import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../shared/constants";
import ApiContainer from "../api/ApiContainer";
import { fetchQuestions, submitAnswers } from "../api/apiSlice";
import { QuizContainer } from "../quiz/QuizContainer";
import { QuizProgress, startQuiz, submitQuiz } from "../quiz/quizSlice";
import { SubmitScreen } from "../quiz/SubmitScreen";
import { SubmittedScreen } from "../quiz/SubmittedScreen";
import { WelcomeScreen } from "../quiz/WelcomeScreen";
import { useTranslation } from "react-i18next";

export function HtmlCssTest() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.api.questions);
  const answers = useSelector((state) => state.quiz.answers);
  const progress = useSelector((state) => state.quiz.progress);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchQuestions("/questions/html-css-test"));
  }, [dispatch]);

  const onSubmit = (submission) => {
    submission.quizType = "html-css-test";
    submission.answers = answers;
    dispatch(submitAnswers(submission));
    dispatch(submitQuiz());
  };

  useEffect(() => {
    if (progress === QuizProgress.submitted) {
      setTimeout(() => {
        if (process.env.NODE_ENV === "production")
          window.location.replace(Constants.redirectUrl);
      }, Constants.redirectDuration);
    }
  }, [progress]);

  return (
    <div>
      <Helmet>
        <title>HTML & CSS Test</title>
      </Helmet>
      <ApiContainer
        success={
          <QuizContainer
            welcomeScreen={
              <WelcomeScreen
                title="Test je kennis van HTML & CSS"
                subtitle={
                  <div>
                    <p>Ontdek in een paar minuten wat jouw niveau is!</p>
                  </div>
                }
                startButton={
                  <button
                    onClick={() => {
                      dispatch(startQuiz(questions));
                    }}
                  >
                    {t("app.startTest")}
                  </button>
                }
              />
            }
            submitScreen={
              <SubmitScreen
                hubspotFormId={Constants.hubspot.htmlCssTestFormId}
                onSubmit={onSubmit}
              />
            }
          />
        }
        submitted={
          <SubmittedScreen redirectUrl={Constants.redirects.htmlCssTest} />
        }
      />
    </div>
  );
}
