import HubspotForm from "react-hubspot-form";
import { Constants } from "../../shared/constants";

export default function HubspotFormContainer({
  hubspotFormId,
  onReady,
  onSubmit,
}) {
  const onHubSpotFormSubmit = () => {
    const form = document.querySelector("iframe").contentDocument.forms[0];
    const data = new FormData(form);
    let submission = {};
    data.forEach((value, key) => (submission[key] = value));
    onSubmit(submission);
  };

  return (
    <HubspotForm
      portalId={Constants.hubspot.portalId}
      formId={hubspotFormId}
      loading={<div style={{ textAlign: "center" }}>...</div>}
      onReady={() => onReady()}
      onSubmit={() => onHubSpotFormSubmit()}
    />
  );
}
