import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../shared/constants";

export const fetchQuestions = createAsyncThunk(
  "api/fetchQuestions",
  async (questionPath) => {
    const res = await fetch(`${Constants.apiUrl}${questionPath}`);
    if (res.status !== 200) {
      console.error("API call failed:", res.status);
      throw res;
    }

    const questions = await res.json();
    return questions;
  }
);

export const fetchPersonalityFollowupQuestions = createAsyncThunk(
  "api/fetchPersonalityFollowupQuestions",
  async (answers) => {
    const res = await fetch(
      `${Constants.apiUrl}/questions/personality-test/followup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(answers),
      }
    );

    const questions = await res.json();
    return questions;
  }
);

export const submitAnswers = createAsyncThunk(
  "api/submitAnswers",
  async (submission) => {
    const response = await fetch(`${Constants.apiUrl}/submit-answers`, {
      method: "POST",
      body: JSON.stringify(submission),
    });

    return response.status;
  }
);

const apiSlice = createSlice({
  name: "api",
  initialState: {
    questions: [],
    status: "initial",
  },
  reducers: {},
  extraReducers: {
    [fetchQuestions.pending]: (state) => {
      state.status = "loading";
    },
    [fetchQuestions.fulfilled]: (state, action) => {
      state.questions = action.payload;
      state.status = "success";
    },
    [fetchQuestions.rejected]: (state) => {
      state.status = "failed";
    },

    [fetchPersonalityFollowupQuestions.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPersonalityFollowupQuestions.fulfilled]: (state, action) => {
      state.questions = action.payload;
      state.status = "success";
    },
    [fetchPersonalityFollowupQuestions.rejected]: (state) => {
      state.status = "failed";
    },

    [submitAnswers.pending]: (state) => {
      state.submitted = true;
      state.status = "loading";
    },
    [submitAnswers.fulfilled]: (state, action) => {
      if (action.payload !== 200) {
        console.error(`Submission failed: ${action.payload}`);
        state.status = "failed";
      } else {
        state.status = "submitted";
      }
    },
    [submitAnswers.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default apiSlice.reducer;
