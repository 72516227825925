import { useSelector } from "react-redux";
import { PersonalityQuizProgress } from "./personalityQuizSlice";
import { QuestionCard } from "../quiz/QuestionCard";
import {
  selectAnswer,
  previousQuestion,
  submitSelectedAnswer,
} from "./personalityQuizSlice";

export function PersonalityQuizContainer({
  welcomeScreen,
  followupScreen,
  submitScreen,
}) {
  const progress = useSelector((state) => state.personalityQuiz.progress);
  const index = useSelector((state) => state.personalityQuiz.index);
  const questions = useSelector((state) => state.personalityQuiz.questions);

  const renderQuiz = () => {
    switch (progress) {
      case PersonalityQuizProgress.initial:
      default:
        return welcomeScreen;

      case PersonalityQuizProgress.firstHalf:
      case PersonalityQuizProgress.secondHalf:
        const question = questions[index];
        let params = { ...question };
        params.index = index;
        // use the personalityQuizSlice dispatchers instead of the default ones from quizSlice
        params.selectAnswerFunc = selectAnswer;
        params.previousQuestionFunc = previousQuestion;
        params.submitSelectedAnswerFunc = submitSelectedAnswer;
        params.namespace = "personality_test";
        return <QuestionCard {...params} />;

      case PersonalityQuizProgress.firstHalfDone:
        return followupScreen;

      case PersonalityQuizProgress.done:
        return submitScreen;
    }
  };

  return <div>{renderQuiz()}</div>;
}
