import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../shared/constants";
import ApiContainer from "../api/ApiContainer";
import { fetchQuestions, submitAnswers } from "../api/apiSlice";
import { QuizContainer } from "../quiz/QuizContainer";
import { startQuiz } from "../quiz/quizSlice";
import { SubmitScreen } from "../quiz/SubmitScreen";
import { SubmittedScreen } from "../quiz/SubmittedScreen";
import { WelcomeScreen } from "../quiz/WelcomeScreen";
import { useTranslation } from "react-i18next";

export function ItCareerTest() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.api.questions);
  const answers = useSelector((state) => state.quiz.answers);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchQuestions("/questions/it-career-test"));
  }, [dispatch]);

  const onSubmit = (submission) => {
    submission.quizType = "it-career-test";
    submission.answers = answers;
    submission.app_language = i18n.language;
    dispatch(submitAnswers(submission));
  };

  return (
    <div>
      <Helmet>
        <title>IT Carrière Test</title>
      </Helmet>
      <ApiContainer
        success={
          <QuizContainer
            namespace={"it_career"}
            welcomeScreen={
              <WelcomeScreen
                // title="IT Carrière Test"
                title={t("it_career:header")}
                subtitle={
                  <div>
                    <p>{t("it_career:title")}</p>
                    <p>{t("it_career:subtitle0")}</p>
                    <p>{t("it_career:subtitle1")}</p>
                    <p>{t("it_career:subtitle2")}</p>
                    <p>{t("it_career:subtitle3")}</p>
                  </div>
                }
                startButton={
                  <button
                    onClick={() => {
                      dispatch(startQuiz(questions));
                    }}
                  >
                    {t("app.startTest")}
                  </button>
                }
              />
            }
            submitScreen={
              <SubmitScreen
                hubspotFormId={
                  i18n.language === "en"
                    ? Constants.hubspot.itCareerTestFormIdEn
                    : Constants.hubspot.itCareerTestFormId
                }
                onSubmit={onSubmit}
              />
            }
          />
        }
        submitted={
          <SubmittedScreen
            redirectUrl={
              i18n.language === "en"
                ? Constants.redirects.itCareerTestEn
                : Constants.redirects.itCareerTest
            }
          />
        }
      />
    </div>
  );
}
