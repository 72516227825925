import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Registering the back-end plugin
  .use(HttpApi)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      ns: [
        "common",
        "personality_test",
        "mvt",
        "mvt_hbo1",
        "mvt_hbo2",
        "mvt_hbo3",
        "it_career",
      ],
      debug: process.env.NODE_ENV === "development",
      // allow keys to be phrases having `:`, `.`
      // nsSeparator: false,
      // keySeparator: false,
      fallbackLng: "nl",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {},
    }
    // (err, t) => {
    //   // i18n.t('myKey'); // key in moduleA namespace (defined default)
    //   // i18n.t('common:myKey'); // key in common namespace (not recommended with ns prefix when used in combination with natural language keys)
    //   // better use the ns option:
    //   i18n.t("myKey", { ns: "common" });
    // }
  );

export default i18n;
