import { configureStore } from "@reduxjs/toolkit";
import apiReducer from "../features/api/apiSlice";
import quizReducer from "../features/quiz/quizSlice";
import personalityQuizReducer from "../features/personality_test/personalityQuizSlice";
import timerReducer from "../features/quiz/timerSlice";

export const store = configureStore({
  reducer: {
    api: apiReducer,
    quiz: quizReducer,
    personalityQuiz: personalityQuizReducer,
    timer: timerReducer,
  },
});
