import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../shared/constants";
import ApiContainer from "../api/ApiContainer";
import {
  fetchQuestions,
  fetchPersonalityFollowupQuestions,
  submitAnswers,
} from "../api/apiSlice";
import { PersonalityQuizContainer } from "./PersonalityQuizContainer";
import {
  PersonalityQuizProgress,
  startQuiz,
  startFollowupQuiz,
  submitQuiz,
} from "./personalityQuizSlice";
import { SubmitScreen } from "../quiz/SubmitScreen";
import { SubmittedScreen } from "../quiz/SubmittedScreen";
import { WelcomeScreen } from "../quiz/WelcomeScreen";
import { useTranslation } from "react-i18next";

export function PersonalityTest() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.api.questions);
  const answers = useSelector((state) => state.personalityQuiz.answers);
  const extra_answers = useSelector(
    (state) => state.personalityQuiz.extra_answers
  );
  const progress = useSelector((state) => state.personalityQuiz.progress);
  const { t, i18n } = useTranslation();

  
  useEffect(() => {
    dispatch(fetchQuestions("/questions/personality-test"));
  }, [dispatch]);

  useEffect(() => {
    if (progress === PersonalityQuizProgress.firstHalfDone) {
      dispatch(fetchPersonalityFollowupQuestions(answers));
    }
  }, [dispatch, answers, progress]);

  useEffect(() => {});

  const onSubmit = (submission) => {
    console.debug("submitting answers to Winc API");
    submission.quizType = "personality-test";
    submission.answers = answers;
    submission.extra_answers = extra_answers;
    submission.app_language = i18n.language;
    dispatch(submitAnswers(submission));
    dispatch(submitQuiz());
  };
  
  // Update `SubmitScreen` props to use relevant HubSpot form & redirect URL depending on language
  let hubSpotPersonalityTestFormId = '';
  let redirectUrl = '';
  const {
    personalityTestFormId,
    personalityTestFormIdEn,
    personalityTestFormIdDe,

  } = Constants.hubspot;

  const {
    personalityTest,
    personalityTestEn,
    personalityTestDe,

  } = Constants.redirects;

  switch (i18n.language) {
    case "nl": 
      hubSpotPersonalityTestFormId = personalityTestFormId;
      redirectUrl = personalityTest;
      break;
    case "de": 
      hubSpotPersonalityTestFormId = personalityTestFormIdDe;
      redirectUrl = personalityTestDe;
      break;
    default: 
      hubSpotPersonalityTestFormId = personalityTestFormIdEn;
      redirectUrl = personalityTestEn;
      break;
  }
  
  return (
    <div>
      <Helmet>
        <title>{t("personality_test:header")}</title>
      </Helmet>
      <ApiContainer
        success={
          <PersonalityQuizContainer
            welcomeScreen={
              <WelcomeScreen
                title={t("personality_test:header")}
                subtitle={
                  <div>
                    <p>
                      {t("personality_test:title")}
                      {t("personality_test:subtitle0")}
                    </p>
                    <p>{t("personality_test:subtitle1")}</p>
                  </div>
                }
                startButton={
                  <button
                    onClick={() => {
                      dispatch(startQuiz(questions));
                    }}
                  >
                    {t("app.startTest")}
                  </button>
                }
              />
            }
            followupScreen={
              <WelcomeScreen
                subtitle={
                  <div>
                    <p>{t("personality_test:halfwayDone0")}</p>
                    <p>{t("personality_test:halfwayDone1")}</p>
                  </div>
                }
                startButton={
                  <button
                    onClick={() => {
                      dispatch(startFollowupQuiz(questions));
                    }}
                  >
                    {t("app.continue")}
                  </button>
                }
              />
            }
            submitScreen={
              <SubmitScreen
                hubspotFormId={hubSpotPersonalityTestFormId}
                onSubmit={onSubmit}
              />
            }
            onSubmit={onSubmit}
          />
        }
        submitted={
          <SubmittedScreen
            redirectUrl={redirectUrl}
          />
        }
      />
    </div>
  );
}
