import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Helmet, HelmetProvider } from "react-helmet-async";
import i18n from "./i18n";

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Helmet>
          <title>Winc Quiz</title>
        </Helmet>
        <React.Suspense fallback="Loading...">
          <App />
        </React.Suspense>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

const domain = window.location.host;


if (domain.includes(".com")) i18n.changeLanguage("en");
if (domain.includes(".nl")) i18n.changeLanguage("nl");

//German translation
// let languageToSet = "en";

// if (domain.includes(".com")) languageToSet = "en";
// if (domain.includes(".nl")) languageToSet = "nl";
// if (domain.includes("/de/") || domain.includes(".de")) languageToSet = "de";

//Development
// if (domain.includes("localhost")) {
//   let path = window.location.pathname;
//   if (path.startsWith("/com")) languageToSet = "en";
//   if (path.startsWith("/nl")) languageToSet = "nl";
//   if (path.startsWith("/de")) languageToSet = "de";  
// }

// i18n.changeLanguage(languageToSet);

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(app, document.getElementById("root"));

serviceWorker.unregister();
