import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function IndexCounter({ isPersonalityTest = false }) {
  const { t } = useTranslation();
  const index = useSelector((state) =>
    isPersonalityTest ? state.personalityQuiz.index : state.quiz.index
  );
  const questions = useSelector((state) =>
    isPersonalityTest ? state.personalityQuiz.questions : state.quiz.questions
  );

  return (
    <div>
      {t("quiz.questionCount", { current: index + 1, total: questions.length })}
    </div>
  );
}
