import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PersonalityQuizProgress } from "../personality_test/personalityQuizSlice";
import { QuizProgress } from "./quizSlice";

export function ConfirmExit({ children }) {
  const progress = useSelector((state) => state.quiz.progress);
  const personalityProgress = useSelector(
    (state) => state.personalityQuiz.progress
  );

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (process.env.NODE_ENV === "production") {
        if (
          progress === QuizProgress.active ||
          personalityProgress === PersonalityQuizProgress.firstHalf ||
          personalityProgress === PersonalityQuizProgress.firstHalfDone ||
          personalityProgress === PersonalityQuizProgress.secondHalf
        ) {
          return "Zeker weten?";
        }
      }
    }
  }, [progress, personalityProgress]);

  return <div>{children}</div>;
}
